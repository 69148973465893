/* ------------------------------------------------------------ *\
    Section Accordion
\* ------------------------------------------------------------ */

.section-accordion {
    padding: 9.4rem 0 59.4rem;

    @include breakpoint-down(large-desktop) {
        padding: 9.2rem 0 43.7rem;
    }

    @include breakpoint-down(small-desktop) {
        padding-bottom: 25rem; 
    }

    @include breakpoint-down(tablet) {
        padding-bottom: 15rem; 
    }

    @include breakpoint-down(mobile) {
        padding: 2.5rem 0 5.8rem;
    }

    &.border-top {
        @include breakpoint-down(mobile) {
            &:before {
                display: none;
            }
        }
    }

    .section__head {
        @include breakpoint-down(mobile) {
            margin-bottom: 3.6rem;
        }

        h3 {
            @include breakpoint-down(mobile) {
                font-size: 1.3rem;
                line-height: 1.15;
            }
        }
    }

    .section__entry {
        max-width: 108.8rem;
        margin-bottom: 12.8rem;
        font-size: 2.4rem;
        line-height: 1.15;
        letter-spacing: -0.022em;

        @include breakpoint-down(large-desktop) {
            max-width: 80.4rem;
            margin-bottom: 9.5rem;
            letter-spacing: -0.015em;
        }

        @include breakpoint-down(mobile) {
            font-size: 1.5rem;
            line-height: 1.2;
            margin-bottom: 5rem;
        }
    }

    .accordion__content {
        p {
            color: $white-2;
        }
    }

    .shell > .grid {
        @include breakpoint-down(mobile) {
            display: block;
        }
    }
}
