/* ------------------------------------------------------------ *\
	projects
\* ------------------------------------------------------------ */

.projects {
	.grid {
		margin: -3.7rem -2rem;

		@include breakpoint-down(large-desktop) {
			margin: -2.5rem -1.5rem;
		}

		@include breakpoint-down(mobile-medium) {
			margin: -0.6rem;
		}

		.grid__col {
			padding: 3.7rem 2rem;

			@include breakpoint-down(large-desktop) {
				padding: 2.5rem 1.5rem;
			}

			@include breakpoint-down(mobile-medium) {
				padding: 0.6rem;
			}
		}

		.grid__col--1of3 {
			@include breakpoint-down(xs-desktop) {
				flex: 0 0 50%;
				max-width: 50%;
			}

			@include breakpoint-down(mobile-medium) {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}

}