/* ------------------------------------------------------------ *\
	.section-listing 
\* ------------------------------------------------------------ */

.section-listing {
	padding: 5.4rem 0 13.5rem;

	@include breakpoint-down(large-desktop) {
		padding: 3.8rem 0 9.7rem;
	}

	@include breakpoint-down(mobile) {
		padding: 1.1rem 0 3.5rem;
	}

	&:last-child {
		padding-bottom: 9.9rem;

		@include breakpoint-down(large-desktop) {
			padding-bottom: 9.1rem;
		}

		@include breakpoint-down(mobile) {
			padding-bottom: 4rem;
		}
	}

	.shell {
		@include breakpoint-up(large-desktop) {
			padding: 0 4rem;
		}
	}

	.section__head {
		margin-bottom: 4.7rem;

		@include breakpoint-down(large-desktop) {
			margin-bottom: 3.1rem;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 2.5rem;
		}
	}
}