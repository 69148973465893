/* ------------------------------------------------------------ *\
    Logo
\* ------------------------------------------------------------ */

.logo {
    width: 100%;
    max-width: 18.1rem;

    @include breakpoint-down(tablet) {
        max-width: 16rem;
    }

    @include breakpoint-down(mobile) {
        max-width: 11.6rem;
    }

    svg {
        width: 100%;
    }
}
