/* ------------------------------------------------------------ *\
	.section-enquire
\* ------------------------------------------------------------ */

.section-enquire {
	padding: 2.8rem 0 10rem;

	@include breakpoint-down(large-desktop) {
		padding: 2.8rem 0 9.4rem;
	}

	@include breakpoint-down(small-desktop) {
		padding-bottom: 7rem;
	}

	@include breakpoint-down(tablet) {
		padding: 2.6rem 0 4.4rem;
	}

	@include breakpoint-down(mobile) {
		padding: 2.5rem 0 5.8rem;
	}

	&.border-top {
		&:before {
			max-width: unset;
		}
	}

	.section__entry {
		max-width: 58.4rem;
		margin-bottom: 8rem;
		font-family: $ff-berlingske;
		font-size: 3.2rem;
		line-height: 1.19;	

		@include breakpoint-down(large-desktop) {
			font-size: 3.3rem;
			letter-spacing: -0.014em;
			max-width: 54.4rem;
			margin-bottom: 8.1rem; 
			line-height: 1.14;
	    }

	    @include breakpoint-down(mobile) {
	    	font-size: 2.2rem;
	    	line-height: 1.09;
	    	margin-bottom: 2.6rem;
	    	letter-spacing: 0;
	    	padding-right: 1.2rem; 
	    }

		a {
			text-decoration: none; 
			transition: opacity $t-duration;

			@include breakpoint-down(mobile) {
				display: block;
			}

			&:hover {
			    @include breakpoint-up(tablet) {
			        opacity: .6;
			    }
			}
		}
	}

	.section__actions {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		ul {
			list-style: none;
			padding-left: 0;
			display: flex;
			align-items: center;
			margin: -1.1rem;
			flex-wrap: wrap;

			@include breakpoint-down(mobile) {
				margin: -.8rem;
				display: block;
			}

			li {
				padding: 1.1rem;

				@include breakpoint-down(mobile) {
					padding: .8rem;
				}
			}
		}
	}
}