/* ------------------------------------------------------------ *\
    Nav
\* ------------------------------------------------------------ */

.nav {
    --nav-offset: var(--header-height, 7.2rem);
    position: fixed;
    top: var(--nav-offset);
    top: auto;
    bottom: calc(100% - var(--nav-offset));
    left: 0;
    right: 0;
    bottom: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 0;
    overflow: hidden;
    background-color: inherit;
    transition: bottom linear 0.4s, height linear 0.4s;
    font-size: 2rem;
    padding: 13rem #{$shell-gutter} 4rem;
    z-index: 9;
    background-color: $black;
    color: $white;

    @include breakpoint-down(small-desktop) {
        padding-top: 11rem;
    }

    @include breakpoint-down(tablet) {
        padding-top: 6.2rem;
    }

    @include breakpoint-down(mobile) {
        padding: 4.8rem #{$shell-gutter-mobile} 2.9rem;
    }

    ul {
        width: 100%;
        max-width: 50%;
        margin: 0 0 2rem auto;
        //font-size: 11.5rem;
        font-size: 10rem;
        font-family: $ff-berlingske;
        line-height: 1;

        @include breakpoint-down(large-desktop) {
            //font-size: 8.4rem;
            font-size: 7rem;
        }

        @include breakpoint-down(small-desktop) {
            //font-size: 7.6rem;
            font-size: 6.2rem;
        }

        @include breakpoint-down(tablet) {
            max-width: 100%;
            padding-left: 0;
            //font-size: 6rem;
            font-size: 4.8rem;
        }

        @include breakpoint-down(mobile) {
            //font-size: 4.8rem;
            font-size:  3.6rem;
            line-height: 1.15;
            padding-left: 0;
        }

        li {
            transform: translateY(10%);
            opacity: 0;
            transition: transform linear 0.3s, opacity linear 0.3s;
            transition-delay: 0s;
            line-height: 1;
        }

        li + li {
            margin-top: 1.3rem;

            @include breakpoint-down(mobile) {
                margin-top: 0.5rem;
            }
        }

        a {
            display: inline-block;
            text-decoration: none;
            line-height: 1;
            font-weight: 300;
            transition: opacity linear 0.3s, transform 0.5s ease;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 4px;
                left: 0;
                height: 1px;
                background-color: $white;
                transform-origin: right;
                transform: scaleX(0);
                transition: transform linear 0.2s;
            }

            @include breakpoint-down(mobile) {
                font-weight: 400;
            }

            &:hover,
            &:focus {
                @include breakpoint-up(tablet) {
                    // opacity: .6;
					//transform: scale(1.1);

                    &::after {
                        transform-origin: left;
                        transform: scaleX(1);
                    }
                }
            }
        }
    }

    h6 {
        margin: 0;
        color: $c-gray-9a;
    }

    .nav__bar {
        display: flex;
        align-items: flex-end;
        width: 100%;
        max-width: $shell-max-width;
        padding-inline: 2.1rem;
        margin-inline: auto;
        letter-spacing: -0.014em;
        font-size: 1.8rem;
        line-height: 1.22;

        @include breakpoint-down(tablet) {
            padding-inline: 0;
        }

        @include breakpoint-down(mobile) {
            display: block;
            font-size: 1.3rem;
            line-height: 1.15;
        }

        h6 {
            letter-spacing: 0.05em;
        }
    }

    .nav__contacts {
        max-width: 25rem;

        @include breakpoint-down(mobile) {
            max-width: 15rem;
            font-size: 1.3rem;
            line-height: 1.15;
        }

        a {
            display: block;
            text-decoration: none;
        }
    }

    .nav__address {
        margin-inline: 27.2rem auto;

        @include breakpoint-down(tablet) {
            margin-left: 4rem;
        }

        @include breakpoint-down(mobile) {
            margin: 1.8rem 0 0;
            font-size: 1.3rem;
            line-height: 1.15;
        }
    }

    .nav__outro {
        @include breakpoint-down(mobile) {
            display: none;
        }
    }

    &.menu-open {
        bottom: 0;
        height: calc(100vh - var(--nav-offset));
        padding-block: 17.9rem 4rem;
        overflow: auto;

        @include breakpoint-down(small-desktop) {
            padding-top: 11rem;
        }

        @include breakpoint-down(tablet) {
            padding-top: 6.2rem;
        }

        @include breakpoint-down(mobile) {
            padding: 4.8rem #{$shell-gutter-mobile} 2.9rem;
            height: calc(var(--current-vh, 1vh) * 100 - var(--header-offset));
        }

        ul {
            li {
                transform: translateY(0);
                opacity: 1;
            }

            // @for $i from 1 through var(--nav-elements) {
            //     li:nth-child(#{$i}) {
            //         transition-delay: #{$i * 0.15}s;
            //     }
            // }
        }
    }
}

body.bg-black .nav {
    // background-color: $black;
}

.menu-main-menu-container {
    width: 100%;
}