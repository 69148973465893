/* ------------------------------------------------------------ *\
    Fonts
\* ------------------------------------------------------------ */

@font-face {
    font-family: 'Berlingske Serif';
    src: url('../fonts/BerlingskeSerif-BoldItalic.woff2') format('woff2'),
        url('../fonts/BerlingskeSerif-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Berlingske Serif DBd';
    src: url('../fonts/BerlingskeSerif-DBd.woff2') format('woff2'),
        url('../fonts/BerlingskeSerif-DBd.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Berlingske Serif';
    src: url('../fonts/BerlingskeSerif-Regular.woff2') format('woff2'),
        url('../fonts/BerlingskeSerif-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Berlingske Serif';
    src: url('../fonts/BerlingskeSerif-LtItalic.woff2') format('woff2'),
        url('../fonts/BerlingskeSerif-LtItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Berlingske Serif';
    src: url('../fonts/BerlingskeSerif-Lt.woff2') format('woff2'),
        url('../fonts/BerlingskeSerif-Lt.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Berlingske Serif';
    src: url('../fonts/BerlingskeSerif-Md.woff2') format('woff2'),
        url('../fonts/BerlingskeSerif-Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Intl';
    src: url('../fonts/SuisseIntl-Regular.woff2') format('woff2'),
        url('../fonts/SuisseIntl-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

