/* ------------------------------------------------------------ *\
	.section-contact
\* ------------------------------------------------------------ */

.section-contact {
	height: calc(100vh - var(--header-offset));
	padding: 0 #{$shell-gutter};
	overflow-y: auto;

	@include breakpoint-down(tablet) {
		height: calc(var(--vh, 1vh) * 100 + (var(--header-offset, 75px) * -1));
	}

	@include breakpoint-down(mobile) {
		padding: 0 #{$shell-gutter-mobile};
	}

	.section__inner {
		display: flex;
		justify-content: space-between;
		flex-direction: row-reverse;
		align-items: flex-start;
		height: 100%;

		@include breakpoint-down(tablet) {
			flex-direction: column;
			justify-content: unset;
		}
	}

	.section__image {
		flex: 0 0 49.14%;
		max-width: 49.14%;
		text-align: right;
		max-height: 100%;
		position: relative;
		padding-bottom: 58vh; 

		@include breakpoint-down(tablet) {
			max-width: 100%;
			width: 100%;
			padding-bottom: 0;
			flex: 0 0 36.14%;
			flex: 0 0 33.04vh;
		}

		@include breakpoint-down(mobile) {
			flex: 0 0 22.9rem;
		}

		img {
			position: absolute;
			top: 0;
			right: 0;
			width: auto;
			height: auto;
			object-fit: cover;
			max-height: 100%;
			max-width: 100%;

			@include breakpoint-down(tablet) {
				// width: 100%;
				right: 50%;
				height: 100%;
				transform: translateX(50%);
			}

			@include breakpoint-down(mobile) {
				height: auto;
			}
		}
	}

	.section__content {
		margin-top: auto;
		flex: 0 0 49.14%;
		max-width: 49%;
		padding-bottom: 9.1rem;

		@include breakpoint-down(tablet) {
			padding-bottom: 1.7rem;
			margin-top: 2.2rem;
			max-width: 100%;
			width: 100%;
			flex: 1;
			flex-basis: unset;
			display: flex;
			flex-direction: column;
		}
	}

	.section__entry {
		max-width: 39.1rem;
		font-size: 3.2rem;
		line-height: 1.19;
		margin-bottom: 4.4rem;
		font-family: $ff-berlingske;

		@include breakpoint-down(tablet) {
			font-size: 2.2rem;
			margin-bottom: 2.5rem;
			max-width: 26.5rem;
			line-height: 1.09;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.section__info {
		font-size: 2rem;
		line-height: 1.2;
		color: $white-2;
		letter-spacing: -0.016em;

		@include breakpoint-down(tablet) {
			font-size: 1.5rem;
			margin-top: auto;
			color: inherit;
		}

		span {
			color: $black;

			@include breakpoint-down(tablet) {
				font-size: 1.3rem;
				line-height: 1.15;
				color: $white-2;
			}
		}

		ul {
			list-style: none;
		}

		a {
			display: block;
			text-decoration: none;
			transition: opacity $t-duration;

			&:hover {
				@include breakpoint-up(tablet) {
					opacity: .6;
				}
			}
		}

		li {
			@include breakpoint-down(tablet) {
				border-top: 1px solid #DADADA;
				padding-top: .6rem;
				width: 100%;
			}
		}

		li + li {
			margin-top: 2.4rem;

			@include breakpoint-down(tablet) {
				margin-top: 3rem;
			}
		}
	}
}

.bg-black .section-contact {
	.section__info {
		color: $white;

		span {
			color: $white-2;
		}
	}
}