/* ------------------------------------------------------------ *\
    Animate On Scroll
\* ------------------------------------------------------------ */

.animate-on-scroll {
    opacity: 0;
    transform: translateY(0);
    transition: opacity ease-out 1.0s, transform ease-out 1.0s;
}

.animate-on-scroll--slower {
    transition-delay: 0.3s;
}

.animated {
    opacity: 1;
    transform: translateY(0);
}
