/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

html {
    font-size: 62.5%;
}

:root {
    --loaded-progress: 0%;
}

body {
    min-width: 320px;
    background: #fff;
    font-family: $ff-suisse;
    font-weight: $font-weight-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: #000;
    overflow: hidden;

    &.is-loaded {
        overflow: auto;

        &:after,
        &:before {
            opacity: 0;
            pointer-events: none;
        }
    }

    &:after {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: ''; 
        background-color: $black;
        transition: opacity 1s;
        z-index: 99;
    }

    &:before {
        position: fixed;
        top: 0;
        left: 0;
        width: var(--loaded-progress);
        height: 5px;
        background-color: $white;
        content: '';
        transition: width .1s;
        z-index: 100;
    }

    &.menu-open {
        overflow: hidden;
    }

    @include breakpoint-down(small-desktop) {
        font-size: 2.1rem;
    }

    @include breakpoint-down(tablet) {
        font-size: 1.8rem;
    }

    @include breakpoint-down(mobile) {
        font-size: 1.5rem;
        line-height: 1.2;
    }

    section:not([class^="bg-"]),
    .footer {
        background-color: #fff;
    }
}

a {
    color: inherit;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

    &[href^="tel"] {
        text-decoration: none;
    }
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
    margin-bottom: calc(#{$line-height-base}em / 2);
}

.h1,
h1 {
    font-family: $ff-berlingske;
    font-size: 11.5rem;
    font-weight: 300;
    line-height: 1.13;
    letter-spacing: 0.000075em;
    position: relative;
    bottom: -8px;

    @include breakpoint-down(large-desktop) {
        //font-size: 8.4rem;
        font-size: clamp(3.2rem, 5.8vw, 8.4rem);
        line-height: 1.0714;
    }

    @include breakpoint-down(small-desktop) {
        //font-size: 7.6rem;
        font-size: clamp(3.2rem, 5.8vw, 8.4rem);
        line-height: 1.15;
    }

    @include breakpoint-down(tablet) {
       // font-size: 6rem;
        font-size: clamp(3.2rem, 5.8vw, 8.4rem);
        bottom: -3px;
    }

    @include breakpoint-down(mobile) {
       // font-size: 3.2rem;
        font-size: 5.8vw;
        font-size: clamp(3.2rem, 5.8vw, 8.4rem);
        line-height: 1.0625;
    }
}

.h2,
h2 {
    font-family: $ff-berlingske;
    //font-size: 8.4rem;
    //font-size: 4.6vw;
    font-size: clamp(3.2rem, 4.6vw, 8.4rem);
    font-weight: 300;
    line-height: 1.0714;
    position: relative;
    bottom: -8px;

    @include breakpoint-down(small-desktop) {
        //font-size: 6.4rem;
        font-size: clamp(3.2rem, 4.6vw, 8.4rem);
        line-height: 1.15;
    }

    @include breakpoint-down(tablet) {
        //font-size: 5rem;
        font-size: clamp(5rem, 4.6vw, 8.4rem);
        bottom: -3px;
    }

    @include breakpoint-down(mobile) {
        font-size: 3.2rem;
        line-height: 1.0625;
    }
}

.h3,
h3 {
    font-size: 3.3rem;
    font-weight: 400;
    line-height: 1.17;
    letter-spacing: -0.022em;

    @include breakpoint-down(large-desktop) {
        font-size: 2.4rem;
        letter-spacing: -0.015em;    
    }

    @include breakpoint-down(mobile) {
        font-size: 1.5rem;
        line-height: 1.2;
    }
}

.h4,
h4 {
    font-family: $ff-suisse;
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.1667;
    letter-spacing: -0.015em;

    @include breakpoint-down(tablet) {
        font-size: 2rem;
    }

    @include breakpoint-down(mobile) {
        font-size: 1.5rem;
        line-height: 1.15;
    }
}

.h5,
h5 {
    font-size: 2.1rem;
    line-height: 1.62;
    font-weight: 500;
    font-family: $ff-berlingske;
    letter-spacing: -0.016em;

    @include breakpoint-down(mobile) {
        font-size: 1.6rem;
        line-height: 2.13;
        letter-spacing: -0.014em;
    }
}

.h6,
h6 {
    font-family: $ff-suisse;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.2223;

    @include breakpoint-down(large-desktop) {
        font-size: 1.6rem;
    }

    @include breakpoint-down(mobile) {
        font-size: 1.3rem;
        line-height: 1.15;
    }
}

p,
ul,
ol,
dl,
hr,
table,
blockquote {
    margin-bottom: 0.75em;

    @include breakpoint-down(mobile) {
        margin-bottom: 1.2em;
    }
}

h1[class],
h2[class],
h3[class],
h4[class],
h5[class],
h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
    margin-bottom: 0;
}

picture {
    display: block;
}