/* ------------------------------------------------------------ *\
  Helpers
\* ------------------------------------------------------------ */

/*  Clear  */

.clear:after {
    content: "";
    line-height: 0;
    display: table;
    clear: both;
}

/* sr-only */

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

/*  Notext  */

.notext {
    white-space: nowrap;
    text-indent: 100%;
    text-shadow: none;
    overflow: hidden;
}

.notext-old {
    font-size: 0;
    line-height: 0;
    text-indent: -4000px;
}

/*  Hidden  */

[hidden],
.hidden {
    display: none;
}

/*  Disabled  */

[disabled],
.disabled {
    cursor: default;
}

/*  Responsive Helpers  */

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
}

@include breakpoint-down(mobile) {
    .hidden-xs {
        display: none !important;
    }
    .visible-xs-block {
        display: block !important;
    }
    .visible-xs-inline {
        display: inline !important;
    }
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}

@include breakpoint-between(mobile, tablet) {
    .hidden-sm {
        display: none !important;
    }
    .visible-sm-block {
        display: block !important;
    }
    .visible-sm-inline {
        display: inline !important;
    }
    .visible-sm-inline-block {
        display: inline-block !important;
    }
}

@include breakpoint-between(tablet, small-desktop) {
    .hidden-md {
        display: none !important;
    }
    .visible-md-block {
        display: block !important;
    }
    .visible-md-inline {
        display: inline !important;
    }
    .visible-md-inline-block {
        display: inline-block !important;
    }
}

@include breakpoint-up(small-desktop) {
    .hidden-lg {
        display: none !important;
    }
    .visible-lg-block {
        display: block !important;
    }
    .visible-lg-inline {
        display: inline !important;
    }
    .visible-lg-inline-block {
        display: inline-block !important;
    }
}


.border-top {
    position: relative;

    &:before {
        position: absolute;
        top: 1px;
        left: 50%;
        width: 100vw;
        max-width: calc(100vw - #{$shell-gutter} * 2);
        height: 1px;
        background-color: $white-2;
        content: ''; 
        transform: translateX(-50%);

        @include breakpoint-down(mobile) {
            max-width: calc(100vw - #{$shell-gutter-mobile} * 2);
        }
    }

}

/* ------------------------------------------------------------ *\
    Bacground
\* ------------------------------------------------------------ */

.bg-green {
    background-color: $c-green-e6 !important;
}

.bg-green-2 {
    background-image: linear-gradient(180deg, #e6f2f0 50%, #fff 100%) !important;

    @include breakpoint-down(mobile) {
        background-image: linear-gradient(180deg, #E6F2F0 46.62%, rgba(230, 242, 240, 0.00) 117.63%) !important;
    }
}

.bg-white {
    background-color: $c-white;
}


.bg-white {
    background-color: $white;
}

.bg-black {
    background-color: $c-black;
    color: $white;

    .h1,
    h1,
    .h2,
    h2,
    .h3,
    h3,
    .h4,
    h4,
    .h5,
    h5,
    .h6,
    h6 {
        color: $white;
    }

    .nav {
        color: $white;
        
        .h1,
        h1,
        .h2,
        h2,
        .h3,
        h3,
        .h4,
        h4,
        .h5,
        h5,
        .h6,
        h6 {
            color: $white-2;
        }
    }

    .footer {
        a,
        address,
        p  {
            color: $white;
        }
    }

    section:not([class^="bg-"]),
    .footer {
        background-color: $black;
    }

    .wrapper:before {
        background-color: $black;
    }

    .header {
        background-color: $black;

        .logo path {
            fill: $white;
        }
    }
}
