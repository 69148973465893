/* ------------------------------------------------------------ *\
    Shell
\* ------------------------------------------------------------ */

.shell {
  max-width: calc(#{$shell-max-width} + #{$shell-gutter} * 2);
  padding-left: $shell-gutter;
  padding-right: $shell-gutter;
  margin: auto;
  width: 100%;
  background-color: inherit;

  @include breakpoint-down(mobile) {
    max-width: calc(#{$shell-max-width} + #{$shell-gutter-mobile} * 2);
    padding-left: $shell-gutter-mobile;
    padding-right: $shell-gutter-mobile;
  }
}

.shell-alt {
  max-width: 1546px;
}

.shell--fluid {
  max-width: unset;
}

.shell--unset {
  max-width: unset;
  padding: 0;
}
