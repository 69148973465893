/* ------------------------------------------------------------ *\
	.media 
\* ------------------------------------------------------------ */

.media {
	position: relative;
	// pointer-events: none;
	overflow: hidden;
	transition: transform $t-duration;

	.media__video[data-autoplay="false"] {
		pointer-events: auto;
		cursor: pointer;
	}

	.media__video[data-autoplay="true"] {
		pointer-events: none;
	}

 	.plyr--video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(100%);
		transition: transform $t-duration;

		@include breakpoint-down(tablet) {
			height: 113%;
		}

		.plyr,
		.plyr__video-wrapper {
			height: 100%;
			// border-radius: 5rem;
		}
		.plyr--video .plyr__controls {
			display: none !important;
		}
		.plyr__control.plyr__control--overlaid {
			opacity: 0 !important;
			visibility: hidden !important;
		}
		video {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}

		iframe {
			width: 100vw;
			// height: 56.25vw !important; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
			min-height: 102%;
			min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			@include breakpoint-down(mobile) {
				min-height: 100%;			
			}			
		}

		.plyr--vimeo {
			iframe {
				transform: translate(-50%, -50%);
				min-height: calc(100% + 14rem);
			}

			.plyr__video-embed__container {
				display: contents;
			}
		}
	}

	.plyr--vimeo {
		height: calc(100% + 6rem); 
	}

	.plyr--full-ui .plyr__video-embed>.plyr__video-embed__container {
		height: 101%;
		padding-bottom: 0;
		transform: translateY(0);
	}

	picture {
		display: block;
		width: 100%;
		// height: 100%;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: transform $t-duration;
	}

	.media__video + .media__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		transition: opacity $t-duration;

		@include breakpoint-down(tablet) {
			
		}

		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 9rem;
			height: 9rem;
			content: '';
			background: url('../images/ico-play.svg') 0 0 no-repeat;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			transform: translate(-50%, -50%);
			opacity: 1;
			transition: opacity .3s;

			@include breakpoint-down(mobile) {
				width: 4rem;
				height: 4rem;
			}
		}

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 6rem;
			height: 9rem;
			content: '';
			border: 1rem solid $white;
			border-width: 0 1rem 0;
			transform: translate(-50%, -50%);
			opacity: 0;
			transition: opacity .3s;

			@include breakpoint-down(mobile) {
				height: 4rem;
				width: 4rem;
			}	
		}
	}

	// .media__video--mobile {
	// 	display: none;

	// 	@include breakpoint-down(mobile) {
	// 		display: block;
	// 	}
	// }

	// .media__video--mobile + .media__video {
	// 	@include breakpoint-down(mobile) {
	// 		display: none;
	// 	}
	// }

	.is-played {
		+ .media__image {
			opacity: 0;
			pointer-events: none;

			&:after {
				opacity: 0;
			}

			&:before {
				opacity: 1;
			}
		}

		.media__play {
			background-color: $white;

			img {
				opacity: 0; 
				visibility: hidden;
			}

			&:before,
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}


.media-wrapper {
	padding-top: 60%;
	position: relative;

	.media {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 100%;
		min-height: 100%;
		width: 100%;
		height: auto;
		object-fit: cover;
	}
}