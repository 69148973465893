/* ------------------------------------------------------------ *\
	.section-slider
\* ------------------------------------------------------------ */

.section-slider {
	padding-bottom: 19.9rem;

	@include breakpoint-down(large-desktop) {
		padding-bottom: 14.7rem;
	}

	@include breakpoint-down(small-desktop) {
		padding-bottom: 6rem;
	}

	@include breakpoint-down(tablet) {
		padding-bottom: 4rem;
	}

	@include breakpoint-down(mobile) {
		padding-bottom: 2rem;

		+ .section-full-media {
			padding-top: 0;
		}
	}
	
	.shell{
		
		&.small{
			
			 max-width: calc(#{$shell-max-width-small} + #{$shell-gutter} * 2);
			 
			 @include breakpoint-down(tablet) {
				 max-width: calc(#{$shell-max-width} + #{$shell-gutter} * 2);
			}	 
			
		}
		
	}
}