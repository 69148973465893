/* ------------------------------------------------------------ *\
    Section
\* ------------------------------------------------------------ */

.section {
    padding-block: 7.5rem 22.1rem;

    @include breakpoint-down(large-desktop) {
        padding-block: 7.4rem 23rem;
    }

    @include breakpoint-down(small-desktop) {
        padding-block: 5rem 16rem;
    }

    @include breakpoint-down(tablet) {
        padding-bottom: 13rem;
    }

    @include breakpoint-down(mobile) {
        padding-block: 3.4rem 10.9rem;
    }

    &.has-separator {
        .section__head {
            @include breakpoint-down(mobile) {
                padding-bottom: 2.9rem;
                border-bottom: 1px solid $white-2;
            }
        }
    }

    .section__content {
        max-width: 90rem;
        letter-spacing: -0.0149em;

        @include breakpoint-down(large-desktop) {
            max-width: 64rem;
        }

        h4 {
            margin-bottom: 1rem;
            line-height: 1.17;
            margin-bottom: 0;
        }

        p + h4 {
            margin-top: 6.3rem;

            @include breakpoint-down(mobile) {
                margin-top: 2rem;
            }
        }
    }

    .section__actions {
        margin-top: 7.4rem;

        @include breakpoint-down(large-desktop) {
            margin-top: 4.4rem;
        }
    }
}

.section--alt {
    @include breakpoint-down(large-desktop) {
        padding-block: 7.4rem 20.8rem;
    }

    @include breakpoint-down(small-desktop) {
        padding-block: 5rem 16rem;
    }

    @include breakpoint-down(tablet) {
        padding-bottom: 12rem;
    }

    @include breakpoint-down(mobile) {
        padding-block: 3.7rem 8rem;
    }

    .section__head {
        @include breakpoint-down(mobile) {
            padding-bottom: 0; 
        }
    }

    .section__content {
        @include breakpoint-down(large-desktop) {
            max-width: 70rem;
        }
    }
    
    &.pb-none{
	    padding-bottom: 0; 
	    @include breakpoint-down(mobile) {
        	padding-block: 3.7rem 8rem;
		}
	    
    }
    
}


.section--lp {
    padding: 9.4rem 0 16.2rem;

    @include breakpoint-down(large-desktop) {
        padding: 8.2rem 0 13.3rem;
    }

    @include breakpoint-down(tablet) {
        padding-bottom: 8rem; 
    }

    @include breakpoint-down(mobile) {
        padding: 8rem 0 6.1rem;
    }

    .section__head {
        padding-top: 0.1rem;

        @include breakpoint-down(mobile) {
            padding-top: 0; 
        }
    }

    .section__content {
        max-width: 84.1rem;

        @include breakpoint-down(large-desktop) {
            max-width: 77.2rem;
        }
    }
}