/* ------------------------------------------------------------ *\
    Header
\* ------------------------------------------------------------ */

.header {
    padding-block: 2.2rem;
    background-color: $c-white;
    position: relative; 
    z-index: 10;
    transition: background-color .4s;
    transition-delay: .4s;

    @include breakpoint-down(mobile) {
        padding-block: 1.2rem 0.8rem;
    }

    .header__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        background-color: inherit;

        .logo {
            position: relative;
            z-index: 1;

            path {
                transition-delay: .4s;
            }
        }
    }

    .btn-menu span {
        transition-delay: 0s;
    }
}

.header--invisible {
    transform: translateY(-100%);
}

.header--fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    transform: translateY(-100%);
    transition-property: transform, background-color;
  transition-duration: 0.3s, 0.4s;
  transition-timing-function: linear;
  transition-delay: 0s, 0.4s;
}

.header--visible {
    transform: translateY(0);
}

.header--green-mobile:not(.header--fixed) {
    @include breakpoint-down(mobile) {
        background-color: $c-green-e6;

        + .nav {
            // background-color: $c-green-e6;
        }
    }
}

.header.menu-open {
    background-color: $black !important;
    transition-delay: 0s;
    transition: background-color 0s;

    .logo {
        path {
            transition-delay: 0s;
            fill: $white;
        }
    }

    .btn-menu span {
        transition-delay: 0s;
        background-color: $white;
    }
}