/* ------------------------------------------------------------ *\
	.page-article
\* ------------------------------------------------------------ */

.page-article {
	@include breakpoint-down(tablet) {
		display: flex;
		flex-direction: column;
	}

	.article__head,
	.article__body {
		max-width: 91.5rem;
		margin: 0 auto;

		@include breakpoint-down(tablet) {
			max-width: 100%;
		}
	}

	.article__head {
		margin-bottom: 9.9rem;
		padding-top: 10rem;

		@include breakpoint-down(small-desktop) {
			padding-top: 6rem;
			margin-bottom: 6rem;
		}

		@include breakpoint-down(tablet) {
			margin-bottom: 5.5rem;
			padding: 2.9rem 0 5.6rem;
			max-width: unset;

			&:before {
				top: auto;
				bottom: 0;
			}
		}

		span {
			display: inline-block;
			font-size: 2.4rem;
			line-height: 1.17;
			color: $white-2;
			margin-bottom: 2rem;
			letter-spacing: -0.01em;

			@include breakpoint-down(small-desktop) {
				font-size: 2rem;
			}

			@include breakpoint-down(mobile) {
				color: inherit;
				font-size: 1.3rem;
				line-height: 1.15;
				margin-bottom: 0.7rem;
			}
		}

		h1, h2 {
			font-size: 7rem;
			line-height: 1.03;
			font-weight: 300; 

			@include breakpoint-down(small-desktop) {
				font-size: 5rem;
			}

			@include breakpoint-down(tablet) {
				font-size: 4rem;
			}

			@include breakpoint-down(mobile) {
				font-size: 2.2rem;
				line-height: 1.1;
				padding-right: 2rem; 
			}
		}
	}

	.article__image {
		max-width: 123rem;
		margin: 0 auto 11.4rem;
		text-align: center;

		@include breakpoint-down(small-desktop) {
			margin-bottom: 6rem;
		}

		@include breakpoint-down(tablet) {
			order: -1;
			margin-bottom: 2.8rem;
		}
	}

	.article__body {

	}

	.article__entry {
		font-size: 2.4rem;
		line-height: 1.17;
		letter-spacing: -0.014em;
		margin-bottom: 13.5rem;

		@include breakpoint-down(small-desktop) {
			margin-bottom: 7rem;
			font-size: 2rem;
		}

		@include breakpoint-down(mobile) {
			font-size: 1.5rem;
			line-height: 1.2;
			margin-bottom: 5.2rem;
		}

		p {
			margin-bottom: 1.8rem;
		} 

		a {
			text-decoration: underline;

			&:hover {
				@include breakpoint-up(tablet) {
					text-decoration: none;
				}
			}
		}
	}

	.article__gallery {
		.slider__slide picture {
			padding-bottom: 65.4%;

			@include breakpoint-down(mobile) {
				padding-bottom: 57.1%;
			}
		}

		.slider__clip {
			margin-bottom: 3rem;

			@include breakpoint-down(mobile) {
				margin-bottom: 0;
			}
		}

		.slider__counter {
			font-size: 2rem;
			line-height: 1.2;
		}
	}
}

.page-article--light {
	.article__entry {
		color: $white-2;
	}
}