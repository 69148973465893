/* ------------------------------------------------------------ *\
	.banner
\* ------------------------------------------------------------ */

.banner {
	min-height: 80.8rem;
	display: flex;
	padding: 6rem 0 5.7rem;

	@include breakpoint-down(large-desktop) {
		min-height: 57.4rem;
		padding-bottom: 4.1rem;
	}

	@include breakpoint-down(tablet) {
		min-height: 50rem;
	}

	@include breakpoint-down(mobile) {
		min-height: 28.5rem;
		padding: 4.8rem 0 3.3rem;
	}

	.shell {
		margin-bottom: 0;
	}

	.banner__content {
		max-width: 196.4rem;
		padding-left: 1.1rem;
		
		@include breakpoint-down(large-desktop) {
			max-width: 142.4rem;
			padding-left: 0;
		}
	}

	h1 {
		@include breakpoint-down(tablet) {
			font-size: 5.5rem;
		}

		@include breakpoint-down(mobile) {
			font-size: 3.2rem;
			line-height: 1.06;
		}
	}
}