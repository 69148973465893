/* ------------------------------------------------------------ *\
	.tile
\* ------------------------------------------------------------ */

.tile {
	position: relative; 

	&:hover {
		@include breakpoint-up(tablet) {
			.tile__image {
				background-color: $c-gray-98;
				// transform: scale(1.04);
			}

			h5 {
                &:after {
                    transform-origin: left;
                    transform: scaleX(1);
                }
            }
		}
	}

	> a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: inline-block;
		z-index: 2;
	}

	.tile__image {
		position: relative;
		padding-bottom: 66.8%;
		transition: background-color linear 0.4s;
		overflow: hidden;
		margin-bottom: 2.1rem;

		@include breakpoint-down(large-desktop) {
			margin-bottom: 1rem;
		}

		@include breakpoint-down(mobile-medium) {
			padding-bottom: 65.7%;
			margin-bottom: 1.4rem;
		}

		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			mix-blend-mode: multiply;
		}
	}

	h5 {
		position: relative;
		display: inline-block;

		&:after {
			content: "";
			position: absolute;
			right: 0;
			bottom: 4px;
			left: 0;
			height: 1px;
			background-color: $c-black;
			transform-origin: right;
			transform: scaleX(0);
			transition: transform linear 0.2s;
		}

		smalL {
			font-size: 2rem;
			font-family: $ff-suisse;
			font-weight: 400;

			@include breakpoint-down(mobile) {
				font-size: 1.5rem;
				line-height: 1.2;
			}
		}
	}
}