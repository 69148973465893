/* ------------------------------------------------------------ *\
	.section-actions
\* ------------------------------------------------------------ */

.section-actions {
	padding: 2.6rem 0 12rem;

	&.border-top {
		&:before {
			max-width: unset;
		}
	}

	@include breakpoint-down(small-desktop) {
		padding-bottom: 6rem;
	}

	@include breakpoint-down(mobile) {
		padding: 2.2rem 0 2.9rem;
	}

	@include breakpoint-down(mobile) {
		&:before {
			display: none;
		}
	}

	.section__actions {
		list-style: none;
		padding-left: 0;
		display: flex; 
		justify-content: space-between;
		align-items: center;
	}
}