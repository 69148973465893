/* ------------------------------------------------------------ *\
    Grid
\* ------------------------------------------------------------ */

.grid {
    display: flex;
    flex-flow: row wrap;

    .grid__col {
        flex: 1;
    }

    .grid__col--1of2 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .grid__col--1of3 {
        flex: 0 0 calc(100% / 3);
        max-width: calc(100% / 3);
    }

    .grid__col--size-2 {
        flex: 0 0 50.7%;
        max-width: 50.7%;

        @include breakpoint-down(large-desktop) {
            flex: 0 0 50.8%;
            max-width: 50.8%;            
        }

        @include breakpoint-down(mobile) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.grid--gap-1 {
    margin: -1.7rem;

    @include breakpoint-down(large-desktop) {
        margin: -1.6rem;
    }

    @include breakpoint-down(mobile) {
        display: block;
        margin: -1.8rem;
    }

    .grid__col {
        padding: 1.7rem;
        
        @include breakpoint-down(large-desktop) {
            padding: 1.6rem;
        }

        @include breakpoint-down(mobile) {
            padding: 1.8rem;
        }
    }
}

.grid--gap-mobile {
    @include breakpoint-down(mobile) {
        margin: -1.5rem;
    }

    .grid__col {
        @include breakpoint-down(mobile) {
            padding: 1.5rem;
        }
    }
}

.grid__col {
    flex: 1;
}

.grid__col-1 {
    @include breakpoint-down(tablet) {
        max-width: 30%;
    }

    @include breakpoint-down(mobile) {
        max-width: 100%;
    }
}