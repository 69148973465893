/* ------------------------------------------------------------ *\
	.article 
\* ------------------------------------------------------------ */

.article  {
	padding: 2.3rem 0 2.3rem;
	border: 1px solid #C3C3C3;
	border-width: 1px 0;

	@include breakpoint-down(tablet) {
		padding: 2.4rem 0;
	}

	@include breakpoint-down(mobile) {
		padding: 1.9rem 0 2.3rem;
	}

	+ .article {
		border-top: 0;
	}

	.grid {
		margin: -2.4rem;

		@include breakpoint-down(tablet) {
			margin: 0;
		}

		.grid__col {
			padding: 2.4rem;

			@include breakpoint-down(tablet) {
				padding: 0;
			}
		}

		.grid__col--1of3 {
			&:first-child {
				@include breakpoint-down(small-desktop) {
					max-width: 20%;
					flex: 0 0 20%;
				}
			}

			&:not(:first-child) {
				@include breakpoint-down(small-desktop) {
					flex: 0 0 40%;
					max-width: 40%;
				}
			}

			&:nth-child(1) {
				@include breakpoint-down(tablet) {
					order: 2;
				}
			}

			&:nth-child(2) {
				@include breakpoint-down(tablet) {
					order: 3;
				}
			}

			&:nth-child(3) {
				@include breakpoint-down(tablet) {
					order: 1;
				}
			}

			@include breakpoint-down(tablet) {
				flex: 0 0 100% !important;
				max-width: 100% !important ;
			}
		}
	}

	.article__entry {
		font-size: 2rem;
		line-height: 1.2;
		letter-spacing: -0.014em;
		margin-bottom: 4.3rem;
		max-width: 43.8rem;

		@include breakpoint-down(small-desktop) {
			font-size: 1.6rem;
			margin-bottom: 2.2rem;
		}

		@include breakpoint-down(mobile) {
			padding-right: 2rem; 
		}

		h3 {
			font-size: 3.2rem;
			font-family: $ff-berlingske;
			line-height: 1.19;
			margin-bottom: 4.1rem;
			letter-spacing: 0;

			@include breakpoint-down(small-desktop) {
		        font-size: 2.4rem;
		        letter-spacing: -0.015em;
		        margin-bottom: 2.1rem;
		    }

		    @include breakpoint-down(tablet) {
		    	margin-bottom: 0;
		    }

		    @include breakpoint-down(mobile) {
		    	font-size: 2.2rem;
		    	line-height: 1.09;
		    	font-weight: 300;
		    	letter-spacing: 0;
		    }
		}

		p {
			color: $white-2;

			@include breakpoint-down(tablet) {
				display: none;
			}
		}
	}

	.article__info {
		padding-top: 0.2rem;

		@include breakpoint-down(small-desktop) {
	        font-size: 2rem;
	        letter-spacing: -0.015em;
	    }

	    @include breakpoint-down(tablet) {
	    	margin-bottom: 1.6rem;
	    }

	    @include breakpoint-down(mobile) {
	    	font-size: 1.3rem;
	    	line-height: 1.15
	    }

		h3 {
			margin-bottom: 0;
			font-size: 2.4rem;
			@include breakpoint-down(small-desktop) {
		        font-size: 2rem;
		        letter-spacing: -0.015em;
		    }

		    @include breakpoint-down(mobile) {
		    	font-size: 1.3rem;
		    	line-height: 1.15;
		    }
		}

		span {
			color: $white-2;
		}
	}

	.article__image {
		@include breakpoint-down(tablet) {
			margin-bottom: 1.4rem;
			position: relative;
			padding-bottom: 57.3%;
			overflow: hidden;
		}

		img {
			@include breakpoint-down(tablet) {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}


.articles {
	position: relative;
	padding-bottom: 6.5rem; 

	@include breakpoint-down(mobile) {
		padding-bottom: 0;
	}

	.article:first-child {
		@include breakpoint-down(tablet) {
			padding-top: 0;
			border-top: 0;
		}
	}

	.article:last-child {
		@include breakpoint-up(mobile) {
			border-bottom: 0; 
		}
	}
}