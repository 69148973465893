/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    appearance: none;
    background: none;
    border: none;
    padding: 1.1rem 2rem 0.9rem;
    font-family: $ff-suisse;
    font-size: 1.6rem;
    line-height: 1.25;
    letter-spacing: -0.015em;
    text-align: center;
    border-radius: 100px;
    background-color: $c-green-e6;
    transition: background-color linear 0.3s, color linear 0.3s;

    @include breakpoint-down(mobile) {
        padding-inline: 1.8rem;
        font-size: 1.3rem;
        line-height: 1.15;
    }

    &:hover {
        background-color: $c-black;
        color: $c-white;
    }
}
