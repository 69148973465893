/* ------------------------------------------------------------ *\
	.wrapper
\* ------------------------------------------------------------ */

.wrapper {
	min-height: 100vh;
	overflow: hidden;
	padding-top: var(--header-height, 0);

	&:before {
		position: absolute; 
		top: 0;
		left: 0;
		width: 100%; 
		height: var(--header-height, 0);
		content: ''; 
		background-color: $white;
	}
}
