/* ------------------------------------------------------------ *\
    Accordion
\* ------------------------------------------------------------ */

.accordion {
	
    counter-reset: accordion;

    .accordion__head {
        position: relative;
        padding: 2.6rem 5rem 2.2rem 23.9rem;

        @include breakpoint-down(large-desktop) {
            padding: 1.9rem 5rem 1.8rem 17.7rem;
        }

        @include breakpoint-down(small-desktop) {
            padding-left: 10rem;
        }

        @include breakpoint-down(mobile) {
            padding: 1.6rem 5rem 1.6rem 4.3rem;
        }

        &.is-open:after {
            transform: translateY(-50%) rotate(45deg);

            @include breakpoint-down(mobile) {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        &:before {
            position: absolute; 
            top: calc(50% - .2rem);
            left: 0;
            transform: translateY(-50%);
            display: inline-block;
            content: counter(section, decimal-leading-zero);
            font-size: 2.4rem;
            line-height: 1.2;
            color: $white;

            @include breakpoint-down(large-desktop) {
                font-size: 1.8rem;
                line-height: 1.22;
                letter-spacing: -0.01em;
                top: calc(50% - 0rem);
            }

            @include breakpoint-down(mobile) {
                font-size: 1.5rem;
                line-height: 1.2;
            }
        }
        

        &:after {
            position: absolute; 
            top: calc(50% - .2rem);
            right: 0rem;
            width: 3.4rem;
            height: 3.4rem;
            transform: translateY(-50%);
            background: url('../images/ico-plus.svg') 0 0 no-repeat;
            background-size: contain;
            background-position: center center;
            content: '';
            transition: transform $t-duration;

            @include breakpoint-down(large-desktop) {
                width: 2.5rem;
                height: 2.5rem;
                top: calc(50% - 0rem);
            }

            @include breakpoint-down(mobile) {
                width: 1.7rem;
                height: 1.7rem;
                right: 0rem;
               
                background-size: contain;
                background-position: center center;
            }
        }

        h3 {
            line-height: 1.22; 
			font-size: 2.4rem;

            @include breakpoint-down(large-desktop) {
                line-height: 1.17;
            }

            @include breakpoint-down(mobile) {
	            font-size: 1.5rem;
                line-height: 1.14;
            }
        }
        
    }

    .accordion-item {
        counter-increment: section;
        border: 1px solid #9A9A9A;
        border-width: 1px 0;
    }

    .accordion-item + .accordion-item {
        border-top: none;
    }

    .accordion__body {
        display: none;
        padding: 0 5rem 2.6rem 23.9rem;

        @include breakpoint-down(large-desktop) {
            padding: 1.1rem 13rem 3.9rem 17.7rem;
            letter-spacing: -0.015em;
        }

        @include breakpoint-down(small-desktop) {
            padding-left: 10rem; 
        }

        @include breakpoint-down(mobile) {
            padding: 1.6rem 4rem 3.9rem 4.3rem;
        }
    }
    
}

.accordion {
	
	&.team{
	
	    counter-reset: accordion;
	
	    .accordion__head {
	        position: relative;
	        padding: 2.6rem 5rem 2.2rem 0rem;
	       
	
	        @include breakpoint-down(large-desktop) {
	            padding: 1.9rem 5rem 1.8rem 0rem;
	        }
	
	        @include breakpoint-down(small-desktop) {
	            padding-left: 0rem;
	        }
	
	        @include breakpoint-down(mobile) {
	            padding: 1.6rem 5rem 1.6rem 0rem;
	        }
	
	        &.is-open:after {
	            transform: translateY(-50%) rotate(45deg);
	
	            @include breakpoint-down(mobile) {
	                transform: translateY(-50%) rotate(180deg);
	            }
	        }
	        
	        &:before {
	            position: absolute; 
	            top: calc(50% - .2rem);
	            left: 0;
	            transform: translateY(-50%);
	            display: inline-block;
	            content: '';
	            font-size: 2.4rem;
	            line-height: 1.2;
	            color: $white;
	
	            @include breakpoint-down(large-desktop) {
	                font-size: 1.8rem;
	                line-height: 1.22;
	                letter-spacing: -0.01em;
	                top: calc(50% - 0rem);
	            }
	
	            @include breakpoint-down(mobile) {
	                font-size: 1.5rem;
	                line-height: 1.2;
	            }
	        }
	
	        	
	        &:after {
	            position: absolute; 
	            top: calc(50% - .2rem);
	            right: 0rem;
	            width: 3.4rem;
	            height: 3.4rem;
	            transform: translateY(-50%);
	            background: url('../images/ico-plus.svg') 0 0 no-repeat;
	            background-size: contain;
	            background-position: center center;
	            content: '';
	            transition: transform $t-duration;
	
	            @include breakpoint-down(large-desktop) {
	                width: 2.5rem;
	                height: 2.5rem;
	              
	                top: calc(50% - 0rem);
	            }
	
	            @include breakpoint-down(mobile) {
	                width: 1.7rem;
	                height: 1.7rem;
	                right: 0rem;
	                //background: url('../images/ico-arrow-down.svg') 0 0 no-repeat;
	                background-size: contain;
	                background-position: center center;
	            }
	        }
	
	        h3 {
	            line-height: 1.12!important; 
				font-size: 2.5rem;
				display: inline-block;
				min-width: 400px;
				font-family: $ff-berlingske;
				& span{
				display: none;
					font-family: $ff-suisse;
					@include breakpoint-down(xs-desktop) {
						display: inline-block;	
					}	
				}
	            @include breakpoint-down(large-desktop) {
	                line-height: 1.17;
	                min-width: 300px;
	            }
	            @include breakpoint-down(small-desktop) {
	                line-height: 1.17;
	                min-width: 230px;
	                font-size: 2.2rem;
	            }
	            
	            @include breakpoint-down(xs-desktop) {
				 	min-width: auto;
				 	font-size: 2.0rem;
				}
	           
	
	            @include breakpoint-down(mobile) {
		            font-size: 1.5rem;
	                line-height: 1.14;
	                min-width: auto;
	                
	               
	            }
	            
	            &.h-mobile{
		            display: inline-block;
		             @include breakpoint-down(xs-desktop) {
					 	display: none;
						}
		            
		            
	            }
	            &.h-desktop{
		            display: none;
		             @include breakpoint-down(xs-desktop) {
					 	display: inline-block;
						}
		            
		            
	            }
	            
	            
	            
	        }
	        h4 {
	            line-height: 1.22; 
				font-size: 2.4rem;
				display: inline-block;
	            @include breakpoint-down(large-desktop) {
	                line-height: 1.17;
	            }
	            
	           
	
	            @include breakpoint-down(xs-desktop) {
		            font-size: 1.5rem;
	                line-height: 1.14;
	                display: none;
	               
	            }
	            
	            @include breakpoint-down(small-desktop) {
		            font-size: 2.1rem;
		            
		        }    
	        }
	        
	        
	    }
	
	    .accordion-item {
	        counter-increment: section;
	        border: 1px solid #9A9A9A;
	        border-width: 1px 0;
	    }
	
	    .accordion-item + .accordion-item {
	        border-top: none;
	    }

	    .accordion__body {
	        display: none;
	        padding: 0 5rem 2.6rem 0rem;
	
	        @include breakpoint-down(large-desktop) {
	            padding: 1.1rem 13rem 3.9rem 0rem;
	            letter-spacing: -0.015em;
	        }
	
	        @include breakpoint-down(small-desktop) {
	            padding-left: 0rem; 
	        }
	
	        @include breakpoint-down(mobile) {
	            padding: 1.6rem 4rem 3.9rem 0rem;
	        }
	    }
	}  
   
}





