//Variables
$font-size-base: 2.4rem;
$font-weight-base: 400;
$line-height-base: 1.1667;

$ff-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$ff-suisse: "Suisse Intl", Helvetica, Arial, sans-serif;
$ff-serif: Georgia, "Times New Roman", Times, serif;
$ff-berlingske: "Berlingske Serif", serif;

$t-duration: 0.4s;

$shell-max-width: 254rem;
$shell-max-width-small: 180rem;
$shell-gutter: 3rem;
$shell-gutter-mobile: 2rem;

$c-black: #000;
$black: #000;
$c-white: #fff;
$white: #fff;
$white-2: #9A9A9A;
$c-base: #003c71;

$c-green-e6: #e6f2f0;

$c-gray-56: #565656;
$c-gray-97: #979797;
$c-gray-98: #989898;
$c-gray-9a: #9a9a9a;
$c-gray-b4: #b4b4b4;

$responsive-ios: 812px;

$breakpoints: (
    mobile-small: 374px,
    mobile-medium: 575px,
    mobile: 767px,
    admin-bar: 782px,
    tablet: 1023px,
    xs-desktop: 1199px,
    small-desktop: 1590px,
    large-desktop: 1920px,
);
