/* ------------------------------------------------------------ *\
    Section Mosaic
\* ------------------------------------------------------------ */

.section-mosaic {
    padding-bottom: 6.1rem;

    @include breakpoint-down(large-desktop) {
        padding-bottom: 4.7rem;
    }

    @include breakpoint-down(mobile) {
        padding-bottom: 7.1rem;
    }

    .section__head {
        margin-bottom: 28.6rem;
        padding-top: 3.3rem;

        @include breakpoint-down(large-desktop) {
            margin-bottom: 15.9rem;
        }

        @include breakpoint-down(small-desktop) {
            margin-bottom: 12rem;
            padding-top: 2.8rem;
        }

        @include breakpoint-down(tablet) {
            margin-bottom: 8rem;
        }

        @include breakpoint-down(mobile) {
            padding-top: 1.6rem;
            margin-bottom: 3.7rem;
        }
    }
}
