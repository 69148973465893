/* ------------------------------------------------------------ *\
	.section-press
\* ------------------------------------------------------------ */

.section-press {
	padding: 16.8rem 0 8rem;

	@include breakpoint-down(tablet) {
		padding: 0 0 3rem;
	}

	.section__actions {
		text-align: center;
		padding-top: 4.8rem;
		position: relative; 

		&:before {
			position: absolute;
			top: 0;
			left: 50%;
			width: 100vw;
			height: 1px;
			content: '';
			background-color: #C3C3C3;
			transform: translateX(-50%); 

			@include breakpoint-down(mobile) {
				display: none;
			}
		}

		@include breakpoint-down(mobile) {
			padding-top: 4.6rem;
		}

		.btn {
			@include breakpoint-down(mobile) {
				display: block;
			}
		}
	}
}