/* ------------------------------------------------------------ *\
    Section Image
\* ------------------------------------------------------------ */

.section-image {
    padding-bottom: 6rem;

    @include breakpoint-down(large-desktop) {
        padding-bottom: 3.9rem;
    }

    @include breakpoint-down(mobile) {
        padding-bottom: 3.4rem;
    }

    .section__head {
        max-width: 115rem;
        margin-bottom: 20.9rem;

        @include breakpoint-down(tablet) {
            margin-bottom: 11rem;
        }

        @include breakpoint-down(mobile) {
            margin-bottom: 7.7rem;
        }
    }

    .section__image {
        max-width: 100rem;

        @include breakpoint-down(large-desktop) {
            max-width: 74.9rem;
        }

        @include breakpoint-down(mobile) {
            aspect-ratio: 350 / 230;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    
    &.push-up{
	    margin-top: -10rem;
	    @include breakpoint-down(mobile) {
        	margin-top: 0px;
    	}
    }
}

.section-image--reverse {
    .section__image {
        // margin-bottom: 0;
    }

    .section__head {
        margin-bottom: 0;
        margin-top: 26.6rem;
        
        @include breakpoint-down(large-desktop) {
            margin-top: 19.8rem;
        }

        @include breakpoint-down(tablet) {
            margin-top: 11rem;
        }

        @include breakpoint-down(mobile) {
            margin-top: 5.2rem;
        }
        
        &.adj-margin{
	        margin-top: 16.6rem; 
	        
	         @include breakpoint-down(large-desktop) {
			 	margin-top: 9.8rem;
        	}

        	@include breakpoint-down(tablet) {
           	 	margin-top: 8rem;
        	}

			@include breakpoint-down(mobile) {
            	margin-top: 5.2rem;
        	}
        }
    }
}


.section-image--lg {
    padding-bottom: 24.1rem;

    @include breakpoint-down(large-desktop) {
        padding-bottom: 18.2rem;
    }

    @include breakpoint-down(tablet) {
        padding-bottom: 11rem; 
    }

    @include breakpoint-down(mobile) {
        padding-bottom: 2.8rem; 
    }

    .section__image {
        max-width: 167.2rem;

        @include breakpoint-down(large-desktop) {
            max-width: 122.9rem;
        }

        @include breakpoint-down(small-desktop) {
            max-width: 66.04%;
        }

        @include breakpoint-down(tablet) {
            max-width: 100%;
        }
    }

    .section__head {
        max-width: 182.7rem;
        padding-left: 1.1rem; 

        @include breakpoint-down(large-desktop) {
            max-width: 139.7rem;
            padding-left: 0;
        }

        h1 {
            @include breakpoint-down(tablet) {
                font-size: 5rem;
            }

            @include breakpoint-down(mobile) {
                font-size: 3.2rem;
                line-height: 1.0625;
            }
        }
    }
}
