/* ------------------------------------------------------------ *\
    Footer
\* ------------------------------------------------------------ */

.footer {
    border-top: 1px solid $c-gray-b4;
    padding-block: 2rem 11.8rem;
    font-size: 1.8rem;

    @include breakpoint-down(large-desktop) {
        padding-block: 1.7rem 12.4rem;
    }

    @include breakpoint-down(small-desktop) {
        padding-bottom: 4rem;
    }

    @include breakpoint-down(mobile) {
        border-top: none;
        font-size: 1.5rem;
        line-height: 1.2;
    }

    .footer__cols {
        display: flex;

        @include breakpoint-down(mobile) {
            flex-wrap: wrap;
            gap: 3rem;
        }
    }

    .footer__col-1 {
        @include breakpoint-down(mobile) {
            width: 100%;
        }
    }

    .footer__col-2 {
        max-width: 16.6rem;
        margin-left: auto;

        @include breakpoint-down(mobile) {
            order: 1;
            width: 100%;
            max-width: 100%;
            margin-inline: 0 auto;
        }

        .footer--right-menu {
            ul {
                list-style: none;
                padding-inline-start: 0;
                margin-top: 2.1rem;

                @include breakpoint-down(mobile) {
                    margin-top: 1.7rem;
                }

                a {
                    text-decoration: none;
                    &:hover {
                        @include breakpoint-up(tablet) {
                            opacity: 0.4;
                        }
                    }
                }
            }
        }
    }

    .footer__col-3 {
        max-width: 18.2rem;
        margin-inline: 15.3rem 9.7rem;

        @include breakpoint-down(large-desktop) {
            margin-inline: 14.7rem 9.3rem;
        }

        @include breakpoint-down(small-desktop) {
            margin-inline: 8rem 0;
        }

        @include breakpoint-down(mobile) {
            width: 100%;
            max-width: 100%;
            margin-inline: 0 auto;
        }
    }

    h6 {
        margin-bottom: 0;
        color: $c-gray-9a;

        @include breakpoint-down(mobile) {
            border-top: 1px solid $c-gray-b4;
            padding-top: 0.8rem;
            margin-bottom: 0.1rem;
        }
    }

    .footer__nav {
        font-size: 1.8rem;

        @include breakpoint-down(mobile) {
            font-size: 1.5rem;
            line-height: 1.2;
        }

        ul {
            list-style: none;
        }

        a {
            position: relative;
            text-decoration: none;
            overflow: hidden;
            letter-spacing: -0.015em;
            line-height: 1.2;
            transition: opacity $t-duration;

            &:hover {
                &:hover {
                    @include breakpoint-up(tablet) {
                        color: $white-2;
                    }
                }
            }
        }
    }

    .footer__address {
        letter-spacing: -0.015em;

        address {
            line-height: 1.3;
        }

        p {
            margin: 0;
        }

        address + p {
            margin-top: 2.1rem;

            @include breakpoint-down(mobile) {
                margin-top: 1.7rem;
            }
        }
    }

    .footer__contacts {
        a {
            display: block;
            text-decoration: none;
            letter-spacing: -0.015em;
            line-height: 1.3;
            transition: opacity $t-duration;

            @include breakpoint-down(mobile) {
                line-height: 1.2;
            }

            &:hover {
                @include breakpoint-up(tablet) {
                    color: $white-2;
                }
            }
        }

        a + h6 {
            margin-top: 2rem;

            @include breakpoint-down(mobile) {
                margin-top: 3.5rem;
            }
        }
    }
}
