/* ------------------------------------------------------------ *\
	.slider
\* ------------------------------------------------------------ */

.slider-gallery {
	max-width: 211rem;
	margin: 0 auto;
	text-align: center;

	@include breakpoint-down(large-desktop) {
		max-width: 154.9rem;	
	}

	.slider__clip {
		margin-bottom: 4.9rem;
		position: relative;

		@include breakpoint-down(large-desktop) {
			margin-bottom: 3.7rem;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 0;
		}
	}

	.slider__counter {
		display: inline-block;
		margin: 0 auto;
		font-size: 2.7rem;
		line-height: 1.2;
		font-weight: 400; 

		@include breakpoint-down(large-desktop) {
			font-size: 2rem;
			line-height: 1.2;
		}

		@include breakpoint-down(mobile) {
			display: none;
		}
	}

	.slick-arrow {
		font-size: 0;
		background-color: unset;
		opacity: 0;
		position: absolute;
		top: 0;
		height: 100%;
		width: 50%;
		z-index: 2;

		@include breakpoint-down(tablet) {
			display: none;
		}
	}

	.slick-prev {
		left: 0;

		@include breakpoint-up(tablet) {
			cursor: url('../images/Cursor_Arrow_Left.png'), auto;
		}
	}

	.slick-next {
		right: 0;

		@include breakpoint-up(tablet) {
			cursor: url('../images/Cursor_Arrow_Right.png'), auto;
		}
	}

	.slider__slide {
		@media(max-width: 767px) {
			&.animated {
				opacity: 1 !important;
			}

			z-index: 1!important;

			+ .slider__slide {
				margin-top: 2rem;
			}
		}

		picture {
			position: relative;
			padding-bottom: 57.8%;

			@include breakpoint-down(large-desktop) {
				padding-bottom: 58.1%;
			}

			@include breakpoint-down(mobile) {
				padding-bottom: 65.7%;
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}