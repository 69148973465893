/* ------------------------------------------------------------ *\
	Mosaic
\* ------------------------------------------------------------ */

.mosaic {
	.mosaic__head {
		max-width: 148rem;
		margin-bottom: 3.1rem;

		@include breakpoint-down(large-desktop) {
			margin-bottom: 5.5rem;
		}

		@include breakpoint-down(small-desktop) {
			max-width: 105rem;
			margin-bottom: 3rem;
		}

		@include breakpoint-down(tablet) {
			max-width: 95rem;
		}

		@include breakpoint-down(mobile) {
			max-width: 48rem;
			margin-bottom: 3.5rem;
		}
	}

	.mosaic__body {
		@include breakpoint-down(large-desktop) {
			margin-bottom: 10rem;
			gap: 5.2rem 0;
		}

		@include breakpoint-down(small-desktop) {
			--gutter: 2rem;
			margin-bottom: 7rem;
			gap: 3rem 0;
		}

		@include breakpoint-down(tablet) {
			--gutter: 1.5rem;
			margin-bottom: 5rem;
			gap: 2rem 0;
		}

		@include breakpoint-down(mobile) {
			margin-bottom: 5rem;
			gap: 1.1rem;
		}
	}

	.grid {
		--gutter-x: 3rem;
		--gutter-y: 6.4rem;
		margin: calc(-1 * var(--gutter-y, 6.4rem) / 2) calc(-1 * var(--gutter-x, 3rem) / 2);

		@include breakpoint-down(large-desktop) {
			--gutter-y: 5.4rem;
		}

		@include breakpoint-down(small-desktop) {
			--gutter-x: 2rem;
			--gutter-y: 3rem;
		}

		@include breakpoint-down(tablet) {
			--gutter-x: 1.5rem;
			--gutter-y: 2rem;
		}

		@include breakpoint-down(mobile) {
			--gutter-y: 1.1rem;
		}

		.grid__col {
			padding: calc(var(--gutter-y, 6.4rem) / 2) calc(var(--gutter-x, 3rem) / 2);
		}

		.grid__col--1of2 {
			@include breakpoint-down(mobile) {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}

	.mosaic__item {
		width: 100%;
		font-size: 2rem;
		line-height: 1.7;
		letter-spacing: -0.015em;
		position: relative;

		@include breakpoint-down(mobile) {
			font-size: 1.5rem;
			line-height: 1.2;
		}

		> a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: inline-block;
			z-index: 2;
		}

		.mosaic__image {
			margin-bottom: 2.5rem;
			background-color: $c-white;
			transition: background-color linear 0.4s;
			overflow: hidden;

			@include breakpoint-down(large-desktop) {
				margin-bottom: 1.1rem;
			}

			@include breakpoint-down(mobile) {
				aspect-ratio: 350/230;
				margin-bottom: 1.5rem;
			}
		}

		img {
			mix-blend-mode: multiply;
			transition: transform $t-duration;

			@include breakpoint-down(mobile) {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		p {
			position: relative;
			overflow: hidden;
			width: max-content;

			&:after {
				content: "";
				position: absolute;
				right: 0;
				bottom: 4px;
				left: 0;
				height: 1px;
				background-color: $c-black;
				transform-origin: right;
				transform: scaleX(0);
				transition: transform linear 0.2s;
			}
		}

		strong {
			font-family: $ff-berlingske;
			font-size: 2.1rem;
			font-weight: 500;
			line-height: 1.6191;

			@include breakpoint-down(mobile) {
				font-size: 1.6rem;
				line-height: 2.125;
			}
		}

		&:hover {
			@include breakpoint-up(tablet) {
				.mosaic__image {
					background-color: $c-gray-98;
					// transform: scale(1.04);
				}

				p {
	                &:after {
	                    transform-origin: left;
	                    transform: scaleX(1);
	                }
	            }
			}
		}
	}
}
