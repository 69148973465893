/* ------------------------------------------------------------ *\
	.section-info
\* ------------------------------------------------------------ */

.section-info {
	padding: 20.9rem 0 33.4rem;

	@include breakpoint-down(large-desktop) {
    	padding: 12.8rem 0 13.3rem;    
    }

    @include breakpoint-down(tablet) {
    	padding: 6.5rem 0 7rem;
    }

    @include breakpoint-down(mobile) {
    	padding: 4.9rem 0 7.6rem;
    }

	.section__content {
		max-width: 61.3rem;
		margin: 0 auto 0 33.7%;

		@include breakpoint-down(large-desktop) {
			margin: 0 17% 0 17%;
		}

		@include breakpoint-down(small-desktop) {
			margin-left: 0;
		}

		@include breakpoint-down(mobile) {
			margin: 0 auto 8.5rem;
			max-width: unset;
		}

		.list-info {
			margin-bottom: 2.4rem;

			@include breakpoint-down(mobile) {
				margin-bottom: 3rem;				
			}
		}
	}

	.section__entry {
		max-width: 61rem;
		margin-left: 1.7rem;
		letter-spacing: -0.015em;
		padding-top: 0.1rem;

		@include breakpoint-down(large-desktop) {
			max-width: 60rem;
			margin-left: 1.5rem;
		}

		@include breakpoint-down(mobile) {
			margin-left: 0;
		}
	}

	.section__inner {
		> .grid {
			@include breakpoint-down(mobile) {
				display: block;
			}
		}
	}
}