/* ------------------------------------------------------------ *\
	.list-info
\* ------------------------------------------------------------ */

.list-info {
	list-style: none;
	padding-left: 0;
	font-size: 1.8rem;
	line-height: 1.22;

	@include breakpoint-down(mobile) {
		font-size: 1.3rem;
		line-height: 1.15;
	}

	li {
		border: 1px solid #C3C3C3;
		border-width: 1px 0;
		display: flex;
		padding: 0.8rem 0 0.5rem;
		justify-content: space-between;

		@include breakpoint-down(mobile) {
			padding: 0.8rem 0 0.6rem;
		}

		span {
			flex: 1;
			max-width: 50%;
			display: inline-block;
			letter-spacing: -0.014em;
		}

		span + span {
			padding-left: .9rem;
		}

		+ li {
			border-top: 0; 
		}
	}
}