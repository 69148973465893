/* ------------------------------------------------------------ *\
    Hero
\* ------------------------------------------------------------ */

.hero {
    .hero__head {
        min-height: 84rem;
        display: flex;
        padding: 11.3rem #{$shell-gutter} 8.2rem;

        @include breakpoint-down(large-desktop) {
            min-height: 60rem;
            padding: 11.3rem #{$shell-gutter} 6.2rem;
        }

        @include breakpoint-down(small-desktop) {
            padding-left: #{$shell-gutter-mobile}; 
            padding-right: #{$shell-gutter-mobile}; 
        }

        h1 {
            margin-top: auto;
            max-width: 159.4rem;

            @include breakpoint-down(large-desktop) {
                max-width: 142.4rem;
            }

            br {
                @include breakpoint-down(tablet) {
                    display: none;
                }
            }
        }
    }

    .hero__media {
        .media {
            .media__video {
                position: relative;
                width: 100%;
                height: 100vh;
                // height: calc(100vh - var(--video-offset));
                // aspect-ratio: 16/9;
                z-index: -1;
                // margin: 0 -#{$shell-gutter};
            }

            .plyr--video {
                position: fixed;
                // aspect-ratio: 16/9;
                top: 0;
                // top: var(--video-offset);
                left: 0;
                bottom: 0;
                z-index: -1;
                height: unset;
            }

            video {
                position: relative;
            }

            picture {
                padding: 0 #{$shell-gutter};

                @include breakpoint-down(mobile) {
                    padding: 0;
                }
            }

            .media__image--full {
                padding: 0 !important;
            }
        }
    }
}

.hero--secondary {
    .hero__head {
        @include breakpoint-down(large-desktop) {
            padding-bottom: 6.9rem;
        }

        @include breakpoint-down(mobile) {
            min-height: 27.5rem;
            padding: 6.3rem 2rem 2.4rem;
        }

        h1 {
            @include breakpoint-down(large-desktop) {
                max-width: 159.4rem;
                padding-left: .7rem;
            }

            @include breakpoint-down(mobile) {
                padding-left: 0;
            }
        }
    }
}

.hero--alt {
    display: block;
    
    @include breakpoint-down(large-desktop) {
        padding-top: 27.5rem;
    }

    @include breakpoint-down(small-desktop) {
        padding-top: 20rem;
    }

    @include breakpoint-down(tablet) {
        padding-top: 15rem;
    }

    @include breakpoint-down(mobile) {
        padding-top: 10.5rem;
    }

    .hero__head {
        @include breakpoint-down(large-desktop) {
            max-width: 128rem;
            margin-bottom: 5.5rem;
        }

        @include breakpoint-down(small-desktop) {
            max-width: 90rem;
        }

        @include breakpoint-down(tablet) {
            margin-bottom: 9.9rem;
        }
    }

    .hero__image {
        max-width: calc(#{$shell-max-width} + #{$shell-gutter} * 2);
        margin-inline: auto;
        padding-inline: $shell-gutter;
        height: auto;
        aspect-ratio: 390 / 270;

        @include breakpoint-down(tablet) {
            padding-inline: 0;
        }
    }
}


//Above fold hero
.hero--full {
    @include breakpoint-down(mobile) {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        min-height: calc(var(--vh, 1vh) * 100 + (var(--header-offset, 60px) * -1));

        .hero__head {
            flex: 1;
            min-height: unset;

            &.hero__head--alt {
                padding: calc(50% + 6rem) #{$shell-gutter-mobile} 0rem;
            }

            h1 {
                margin: auto 0;
                font-size: 32px;
                margin-bottom: 4rem;
            }

            &:not(.hero__head--alt) {
                
                @include breakpoint-down(mobile) {
                    display: flex;
                    align-items: center;
                    padding: 2.5rem 2rem 6.2rem;

                    h1 {
                        margin: unset;
                    }
                }
            }
        }

        .hero__media {
            aspect-ratio: 13/9;

            .media__image {
                aspect-ratio: 13/9;
                position: relative; 

                img {
                    position: absolute; 
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}


.hero--full-alt {
    @include breakpoint-down(mobile) {
        .hero__head {
            padding: 6rem #{$shell-gutter-mobile} 6rem;
        }
    }
}


//Project hero
.hero--project {
    .hero__head {
        min-height: 71rem;
        padding-bottom: 0;

        @include breakpoint-down(large-desktop) {
            min-height: 50.4rem;
        }

        @include breakpoint-down(tablet) {
            min-height: 34.4rem;
            padding-top: 7.3rem;
        }

        @include breakpoint-down(mobile) {
            min-height: 22.6rem;

        }
    }

    .hero__entry {
        flex: 0 0 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        h1 {
            flex: 0 0 80%;
            max-width: 80%;
            margin-bottom: -1.8rem;

            @include breakpoint-down(large-desktop) {
                margin-bottom: -1.1rem;
            }

            @include breakpoint-down(small-desktop) {
                margin-bottom: 0;
                padding-bottom: .6rem;
            }

            @include breakpoint-down(mobile) {
                padding-bottom: 2.9rem;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
    

    .hero__actions {
        padding-bottom: 6.1rem;

        @include breakpoint-down(large-desktop) {
            padding-bottom: 4.8rem;
        }

        @include breakpoint-down(tablet) {
            padding-bottom: 2rem;
        }

        @include breakpoint-down(mobile) {
            display: none;
        }
    }

    h1 {
        font-size: 21rem;
        line-height: 1.41;
        letter-spacing: -0.02em;

        @include breakpoint-down(large-desktop) {
            font-size: 15rem;
            line-height: 1.45;
            letter-spacing: -0.02em;
        }

        @include breakpoint-down(small-desktop) {
            font-size: 11.5rem;
            line-height: 1.13;
            letter-spacing: 0.000075em;
        }

        @include breakpoint-down(tablet) {
            font-size: 8.4rem;
            line-height: 1.0714;
        }

        @include breakpoint-down(mobile) {
            font-size: 5.2rem;
            line-height: 0.81;
            letter-spacing: 0.01em;
        }
    }

    .hero__media {
        .media {
            picture {
                padding: 0;
            }
        }
    }
}
