/* ------------------------------------------------------------ *\
    Section Callout
\* ------------------------------------------------------------ */

.section-callout {
    padding-bottom: 8.7rem;

    @include breakpoint-down(mobile) {
        padding-bottom: 2.2rem;
    }
	
	&.hide-mobile{
		display: block;
		
		 @include breakpoint-down(mobile) {
			 display: none;
			 
		}	 
	}
	
	&.show-mobile{
		display: none;
		
		 @include breakpoint-down(mobile) {
			 display: block;
			 
		}	 
	}
	
	
    .section__inner {
        border-radius: 20px;
        padding: 17.7rem $shell-gutter 5.6rem;

        @include breakpoint-down(large-desktop) {
            padding: 9.8rem 3rem 2rem;
        }

        @include breakpoint-down(mobile) {
            border-radius: 10px;
            padding: 7.4rem 1.1rem 0.9rem;
        }
    }

    .section__head {
        max-width: 129.8rem;
        margin: 0 auto 3.3rem;

        @include breakpoint-down(large-desktop) {
            max-width: 110rem;
            margin-bottom: 8.9rem;
        }

        @include breakpoint-down(mobile) {
            max-width: 33rem;
            margin-bottom: 5.2rem;
        }

        h2 {
            text-align: center;

            @include breakpoint-down(large-desktop) {
                font-size: 7rem;
                line-height: 1.0286;
            }

            @include breakpoint-down(small-desktop) {
                font-size: 5.6rem;
                line-height: 1.15;
            }

            @include breakpoint-down(tablet) {
                font-size: 4.3rem;
            }

            @include breakpoint-down(mobile) {
                font-size: 2.8rem;
            }
        }
    }

    .section__image {
        // max-width: 29.1rem;
        margin-inline: auto;
        text-align: center;

        @include breakpoint-down(small-desktop) {
            // max-width: 26rem;
        }

        @include breakpoint-down(tablet) {
            position: relative;
            padding-bottom: 68%;
        }

        img {
            @include breakpoint-down(tablet) {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }


    }
}

.show-mobile{
	
	.section-callout{
		.section-callout .section__head {
			@include breakpoint-down(mobile) {
				max-width: 33rem;
				margin-bottom: 5.2rem;
			}	
		}
		
		.section__inner {
			@include breakpoint-down(mobile) {
				padding: 7.4rem 1.1rem 0.9rem;
			}	
		}
	}	
}
