/* ------------------------------------------------------------ *\
	.section-full-media
\* ------------------------------------------------------------ */

.section-full-media {
	.media {
		.media__video {
			position: relative;
			width: 100%;
		}

		.plyr--video {
			position: relative;
			height: auto;
		}

		video {
			position: relative;
		}
	}
}

.section-full-media--pb {
	padding-bottom: 12rem;

	@include breakpoint-down(large-desktop) {
		padding-bottom: 11.9rem;
	}

	@include breakpoint-down(mobile) {
		padding-bottom: 6.3rem;
	}
}

.section-full-media--lp {
	padding: 16.7rem 0 29.3rem;

	@include breakpoint-down(large-desktop) {
		padding: 12rem 0 13.8rem;
	}

	@include breakpoint-down(small-desktop) {
		padding: 6rem 0 7.8rem;
	}

	@include breakpoint-down(tablet) {
		padding: 4rem 0 5rem;
	}

	@include breakpoint-down(mobile) {
		padding: 4rem 0 3.4rem;
	}
}