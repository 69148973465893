/* ------------------------------------------------------------ *\
    Button Meny
\* ------------------------------------------------------------ */

.btn-menu {
    position: relative;
    width: 4.5rem;
    height: 1.1rem;

    @include breakpoint-down(tablet) {
        width: 3.8rem;
    }

    @include breakpoint-down(tablet) {
        width: 3rem;
        height: 1rem;
        margin-top: -0.2rem;
    }

    span {
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 0.2rem;
        background-color: $c-black;
        left: 0;

        transition: all linear 0.05s;
		
		&.bg-white{
			 background-color: $white;
		}
        &:first-of-type {
            top: 0;
        }

        &:last-of-type {
            bottom: 0;
        }

        &.bg-white {
            background-color: $white;
        }
    }

    &:hover {
        @include breakpoint-up(tablet) {
            span {
                &:first-of-type {
                    transform: translateY(-0.3rem);
                }

                &:last-of-type {
                    transform: translateY(0.3rem);
                }
            }
        }
    }

    &.menu-open {
        span {
            &:first-of-type {
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
            }

            &:last-of-type {
                bottom: 50%;
                transform: translateY(50%) rotate(-45deg);
            }
        }
    }
}
