/* ------------------------------------------------------------ *\
	.section-article 
\* ------------------------------------------------------------ */

.section-article {
	padding: 12.8rem 0 7.5rem;

	@include breakpoint-down(small-desktop) {
		padding: 8rem 0 6rem;
	}

	@include breakpoint-down(tablet) {
		padding: 0 0 2.3rem;
	}
}