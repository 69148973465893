.section--list-text-items {
  padding: 12rem 0 7.5rem;

  @include breakpoint-down(tablet) {
    padding: 8rem 0 5rem;
  }

  @include breakpoint-down(mobile) {
    padding: 4rem 0;
  }

  .list-text-items__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid #c3c3c3;
    padding-top: 3rem;
    gap: 5rem;

    @include breakpoint-down(tablet) {
      grid-template-columns: 1fr;
      gap: unset;
    }

    & + .list-text-items__item {
      margin-top: 4.5rem;
    }

    h2 {
      font-size: 7rem;
      max-width: 60rem;
      margin-bottom: 5rem;

      @include breakpoint-down(tablet) {
        font-size: 3.2rem;
      }
    }

    h3 {
      max-width: 38rem;
      font-size: 3.2rem;
      font-family: $ff-berlingske;

      @include breakpoint-down(tablet) {
        font-size: 1.6rem;
      }
    }
  }
}
